<template>
  <div class="jump_link_box">
    <div class="jump_link" @click="goTo('/department/diet')">
      主紹介
    </div>
    <div class="jump_link" @click="goTo('/department/diet/price')">
      価格一覧
    </div>
    <div class="jump_link" @click="goTo('/department/diet/guide')">
      診療流れ
    </div>
    <div class="jump_link" @click="goTo('/department/diet/faq')">
      FAQ
    </div>
  </div>
</template>

<script>
export default {
  name: "MDietSidebar",
  data() {
    return {

    }
  },
  props: {
    clinicName: {
      default: '築地'
    },
    pathName: {
      default: 'tsukiji'
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    goHref(path) {
      window.location.href = path;
    }
  }
}
</script>

<style scoped>
.jump_link_box{
  position:fixed;
  left: 0px;
  bottom:0px;
  z-index: 1025;
  width: 100%;
  height: fit-content;
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  vertical-align: top;
  height: auto;
}

.jump_link{
  background-color: #62b6e2;
  border-left: 1px solid #ffffff;
  width: 25%;

  height: 60px;

  color: #ffffff;
  writing-mode:horizontal-tb;
  text-orientation: upright;
  text-align: center;
  vertical-align: middle;

  font-size: 14px;

  line-height: 4.5;

  -webkit-user-select:none;/*webkit浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none;/*IE10*/
  user-select:none;
  cursor: pointer;
}

.jump_link:active{
  background-color: #FFFFFF;
  color:#62b6e2;
  
}


</style>
