<template>
  <div class="jump_link_box">
    <div class="jump_link" @click="goTo('/department/diet')" id="main">
      主紹介
    </div>
    <div class="jump_link" @click="goTo('/department/diet/price') " id="price">
      価格一覧
    </div>
    <div class="jump_link" @click="goTo('/department/diet/guide')" id="guide">
      診療からの流れ
    </div>
    <div class="jump_link" @click="goTo('/department/diet/faq')" id="faq">
      よくある質問
    </div>
    
  </div>
</template>

<script>
export default {
  name: "DietSidebar",
  data() {
    return {
      
    }
  },
  props: {
    clinicName: {
      default: '築地'
    },
    pathName: {
      default: 'tsukiji'
    },
    dietMainPage: {
      default: "main"
    }
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    goHref(path) {
      window.location.href = path;
    }
  },
  mounted() {
    if(this.dietMainPage == "main"){
      document.getElementById("main").className="current_link";
    }
    if(this.dietMainPage == "price"){
      document.getElementById("price").className="current_link";
    }
    if(this.dietMainPage == "guide"){
      document.getElementById("guide").className="current_link";
    }
    if(this.dietMainPage == "faq"){
      document.getElementById("faq").className="current_link";
    }
  },
}
</script>

<style scoped>
.jump_link_box{
  position:fixed;
  left: -5px;
  top:200px;
  z-index: 1025;
  width: 30px;
  height: fit-content;
}

.jump_link{
  background-color: #62b6e2;
  border-radius: 8px;
  width: 35px;
  padding-right: 15px;
  height: 140px;
  margin-top: 8px;

  color: #FFFFFF;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-align: center;
  vertical-align: middle;

  font-size: 18px;

  -webkit-user-select:none;/*webkit浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none;/*IE10*/
  user-select:none;
  cursor: pointer;
  transition: 150ms;
}

.jump_link:hover{
  background-color: #FFFFFF;
  color:#62b6e2;
  
}

.jump_link{
  background-color: #62b6e2;
  border-radius: 8px;
  width: 35px;
  padding-right: 15px;
  height: 140px;
  margin-top: 8px;

  color: #FFFFFF;
  writing-mode: vertical-rl;
  text-orientation: upright;
  text-align: center;
  vertical-align: middle;

  font-size: 18px;

  -webkit-user-select:none;/*webkit浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none;/*IE10*/
  user-select:none;
  cursor: pointer;
  transition: 150ms;
  border: #62b6e2 1px solid;
}

.jump_link:hover{
  background-color: #FFFFFF;
  color:#62b6e2;
  
  
}
.current_link{
  background-color: #ffffff;
  border-radius: 8px;
  width: 60px;
  padding-right: 15px;
  height: 140px;
  margin-top: 8px;
  border: #62b6e2 1px solid;
  color: #62b6e2;
  writing-mode: vertical-rl;
  text-orientation: middle;
  text-align: center;
  vertical-align: middle;

  font-size: 18px;

  -webkit-user-select:none;/*webkit浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none;/*IE10*/
  user-select:none;
  cursor: pointer;
  transition: 150ms;
}


</style>
